import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  OutsideClickHandler,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  const toggleDropdown = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  const handleKeyDown = setIsOpen => e => {
    // Gather all escape presses to close menu
    if (e.keyCode === KEY_CODE_ESCAPE) {
      setIsOpen(false);
    }
  };

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <a href={process.env.REACT_APP_SELLER_SIGNUP_URL} className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </a>
  );


  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <a href={process.env.REACT_APP_SELLER_LOGIN_URL} className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </a>
  );

  return (
    <nav className={classes}>
      <div className={css.leftSideBar}>
        <LinkedLogo
          className={css.logoLink}
          format="desktop"
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
        />
        {search}
        <div className={css.menuBox} >
          <span onClick={toggleDropdown}>
            <svg width="16" height="10" viewBox="18 19 18 12" xmlns="http://www.w3.org/2000/svg">
              <g fill="#34495E" fill-rule="evenodd" transform="translate(18 19)">
                <rect width="18" height="2" rx="1"></rect>
                <rect y="5" width="18" height="2" rx="1"></rect>
                <rect y="10" width="18" height="2" rx="1"></rect>
              </g>
            </svg>
            <span className={css.menuDropdown}>Menu</span>
          </span>
          {isOpenMenu && (
            <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
              <ul className={css.dropdownContent} onKeyDown={handleKeyDown(setIsOpen)}>
                <NamedLink className={css.editLinkDesktop} name="AboutPage">
                  <li>
                    <a ><FormattedMessage id="TopbarDesktopMenu.about" /></a>
                  </li>
                </NamedLink>
                <NamedLink className={css.editLinkDesktop} name="HowItWorkPage">
                  <li>
                    <a ><FormattedMessage id="TopbarDesktopMenu.howitwork" /></a>
                  </li>
                </NamedLink>
                <NamedLink className={css.editLinkDesktop} name="HowItWorkPageBuyers">
                  <li>
                    <a ><FormattedMessage id="TopbarDesktopMenu.howitworkbuyers" /></a>
                  </li>
                </NamedLink>
                <NamedLink className={css.editLinkDesktop}
                name="PrivacyPolicyPage">
                  <li>
                    <a ><FormattedMessage id="TopbarDesktopMenu.privacypolicy" /></a>
                  </li>
                </NamedLink>
                <NamedLink className={css.editLinkDesktop} name="TermsOfServicePage">
                  <li>
                    <a><FormattedMessage id="TopbarDesktopMenu.termservice" /></a>
                  </li>
                </NamedLink>
                <a className={css.editLinkDesktop} href='https://www.promotix.com/contact' >
                  <li>
                    <a><FormattedMessage id="TopbarDesktopMenu.contactsupport" /></a>
                  </li>
                </a>
              </ul>
            </OutsideClickHandler>
          )}
        </div>
      </div>
      <div className={css.rightSideBar}>
        <NamedLink className={css.createListingLink} name="NewListingPage">
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.createListing" />
          </span>
        </NamedLink>
        {inboxLink}
        {profileMenu}
        {signupLink}
        {loginLink}
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
